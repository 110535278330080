<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownRegion">Region</label>
                    <Dropdown v-model="filters.region_id" :loading="loadingDropdownRegion" :options="dataDropdownRegion" optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')" @change="changeWilayah()"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownWilayah">Wilayah</label>
                    <Dropdown v-model="filters.wilayah_id" :loading="loadingDropdownWilayah" :options="dataDropdownWilayah" optionLabel="wilayah_name" optionValue="wilayah_id" placeholder="Pilih Wilayah" :filter="true" :showClear="true" @filter="searchDropdownWilayah($event, 'filter')"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="dropdownDistributor">Distributor</label>
                    <Dropdown v-model="filters.dist_code" :loading="loadingDropdownDistributor" :options="dataDropdownDistributor" optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'filter')"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Brand-Produk</label>
                    <Listbox ref="dbrandproduck" v-model="filters.brandproduct_id" dataKey="product_id" :options="dataDropdownBrandProduct" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownBrandProduct($event, 'filter')">
                        <template #option="slotProps">
                            <div>{{slotProps.option.label}}</div>
                        </template>
                    </Listbox>
                </div>
                <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectBrandProduct(filters.brandproduct_id).length != 0">
                    <div class="card" style="height: calc(60vh - 143px)">
                        <DataTable :value="filters.brandproduct_id" :scrollable="true" scrollHeight="flex">
                            <Column field="label" header="Brand - Produk">
                                <template #body="slotProps">
                                    {{ slotProps.data.label }}
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownRegion: false,
            loadingDropdownWilayah: false,
            loadingDropdownDistributor: false,
            loadingDropdownBrandProduct: false,

            // dataDropdown
            dataDropdownRegion: null,
            dataDropdownWilayah: null,
            dataDropdownDistributor: null,
            dataDropdownBrandProduct: null,

            // filter
            filters: {
                region_id: null,
                wilayah_id: null,
                dist_code: null,
                brandproduct_id: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownRegion('');
        this.searchDropdownDistributor('');
        this.searchDropdownBrandProduct('');
    },
    created(){
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        brand_product_id(){
            return this.convertMultiSelectBrandProduct(this.filters.brandproduct_id)
        }
    },
    methods: {
        convertMultiSelectBrandProduct(data) {
            if(data){
                return data.map((item) => {
                    return item.product_code;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownRegion = res.data.data;
                            this.loadingDropdownRegion = false;
                        } else if (purpose == null) {
                            this.dataDropdownRegion = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeWilayah() {
            this.filters.wilayah_id = null;
            this.dataDropdownWilayah = null;
            this.searchDropdownWilayah('');
        },
        searchDropdownWilayah(event, purpose, valueEdit){
            setTimeout(() => {

                if (this.filters.region_id) {
                    if(valueEdit){
                        this.$refs.dwilayah.filterValue = valueEdit;
                    }

                    if(purpose == "filter"){
                        this.loadingDropdownWilayah = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-wilayah',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "region_id" : this.filters.region_id,
                        }
                    })
                    .then(res => {

                        if(purpose == "filter"){
                            this.dataDropdownWilayah = res.data.data;
                            this.loadingDropdownWilayah = false;
                        }else if(purpose == null){
                            this.dataDropdownWilayah = res.data.data;
                        }
                    })
                    .catch((err) => {
                    console.log(err);
                });
                }
            }, 250);
        },
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownDistributor = res.data.data;
                        this.loadingDropdownDistributor = false;
                    }else if(purpose == null){
                        this.dataDropdownDistributor = res.data.data;
                    }
                })
                .catch((err) => {
                console.log(err);
            });
            }, 250);
        },
        searchDropdownBrandProduct(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dbp.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownBrandProduct = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-product-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownBrandProduct = res.data.data;
                            this.loadingDropdownBrandProduct = false;
                        } else if (purpose == null) {
                            this.dataDropdownBrandProduct = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

