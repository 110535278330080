<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="nama" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.nama}}
                        </template>
                    </Column>
                    <Column field="time_input" header="Waktu Input">
                        <template #body="slotProps">
                            {{slotProps.data.time_input}}
                        </template>
                    </Column>
                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                            {{slotProps.data.region_name}}
                        </template>
                    </Column>
                    <Column field="wilayah_name" header="Wilayah">
                        <template #body="slotProps">
                            {{slotProps.data.wilayah_name}}
                        </template>
                    </Column>
                    <Column field="dist_code" header="Kode Distributor">
                        <template #body="slotProps">
                            {{slotProps.data.dist_code}}
                        </template>
                    </Column>
                    <Column field="dist_name" header="Nama Distributor">
                        <template #body="slotProps">
                            {{slotProps.data.dist_name}}
                        </template>
                    </Column>
                    <Column field="cdistribusi_name" header="Channel Distribusi">
                        <template #body="slotProps">
                            {{slotProps.data.cdistribusi_name}}
                        </template>
                    </Column>
                    <Column field="klasifikasi_id" header="Kode Outlet Klasifikasi">
                        <template #body="slotProps">
                            {{slotProps.data.klasifikasi_id}}
                        </template>
                    </Column>
                    <Column field="klasifikasi_name" header="Nama Outlet Klasifikasi">
                        <template #body="slotProps">
                            {{slotProps.data.klasifikasi_name}}
                        </template>
                    </Column>
                    <Column field="cust_code" header="Kode Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.cust_code}}
                        </template>
                    </Column>
                    <Column field="cust_name" header="Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.cust_name}}
                        </template>
                    </Column>
                    <Column field="cust_address" header="Alamat Outlet">
                        <template #body="slotProps">
                            {{slotProps.data.cust_address}}
                        </template>
                    </Column>
                    <Column field="brand_name" header="Brand">
                        <template #body="slotProps">
                            {{slotProps.data.brand_name}}
                        </template>
                    </Column>
                    <Column field="jenis_garam" header="Jenis Garam">
                        <template #body="slotProps">
                            {{slotProps.data.jenis_garam}}
                        </template>
                    </Column>
                    <Column field="kategori_garam" header="Kategori Garam">
                        <template #body="slotProps">
                            {{slotProps.data.kategori_garam}}
                        </template>
                    </Column>
                    <Column field="product_code" header="Kode Produk">
                        <template #body="slotProps">
                            {{slotProps.data.product_code}}
                        </template>
                    </Column>
                    <Column field="product_name" header="Nama Produk">
                        <template #body="slotProps">
                            {{slotProps.data.product_name}}
                        </template>
                    </Column>
                    <Column field="ukuran_gramasi" header="Gramasi">
                        <template #body="slotProps">
                            {{slotProps.data.ukuran_gramasi}}
                        </template>
                    </Column>
                    <Column field="kg_per_ball" header="Kg/ball">
                        <template #body="slotProps">
                            {{slotProps.data.kg_per_ball}}
                        </template>
                    </Column>
                    <Column field="qty" header="QTY">
                        <template #body="slotProps">
                            {{slotProps.data.qty}}
                        </template>
                    </Column>
                    <Column field="uom" header="UOM">
                        <template #body="slotProps">
                            {{slotProps.data.uom}}
                        </template>
                    </Column>
                    <Column field="price" header="Harga">
                        <template #body="slotProps">
                            {{slotProps.data.price}}
                        </template>
                    </Column>
                    <Column field="rp_amount" header="Amount (Rp)">
                        <template #body="slotProps">
                            {{slotProps.data.rp_amount}}
                        </template>
                    </Column>
                    <Column field="doc_no" header="Doc No">
                        <template #body="slotProps">
                            {{slotProps.data.doc_no}}
                        </template>
                    </Column>
                    <Column field="bayar_kategori" header="Kategori Pembayaran">
                        <template #body="slotProps">
                            {{slotProps.data.bayar_kategori}}
                        </template>
                    </Column>
                    <Column field="bayar_keterangan" header="Keterangan Pembayaran">
                        <template #body="slotProps">
                            {{slotProps.data.bayar_keterangan}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Filter from '../../components/data/order/Filter.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Filter': Filter,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/order',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "region_id" : this.$refs.filter.filters.region_id,
                    "wilayah_id" : this.$refs.filter.filters.wilayah_id,
                    "dist_code" : this.$refs.filter.filters.dist_code,
                    "product_code" : this.$refs.filter.brand_product_id,
                    "start_date" : this.$refs.filter.period_start_label,
                    "end_date" : this.$refs.filter.period_end_label,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/order/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "region_id" : this.$refs.filter.filters.region_id,
                "wilayah_id" : this.$refs.filter.filters.wilayah_id,
                "dist_code" : this.$refs.filter.filters.dist_code,
                "product_code" : this.$refs.filter.brand_product_id,
                "start_date" : this.$refs.filter.period_start_label,
                "end_date" : this.$refs.filter.period_end_label,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Order Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>